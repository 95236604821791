export const axiosConfig = {
    // baseURL: 'https://www.designsense.ai',
    // baseURL: 'https://design.webstaginghub.com',
    baseURL: 'https://designsense2.webstaginghub.com',
    // baseURL: 'https://www.designsense.ai',
    // baseURL: process.env.REACT_APP_ENABLE_LOCALHOST_API==='1' ? window.location.origin : 'https://www.designsense.ai',
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
    },
}
export const generationOptionsConfig = {
    baseURL: 'https://designsense2.webstaginghub.com',
    // baseURL: 'https://www.designsense.ai',
    // baseURL: 'https://design.webstaginghub.com',
    // baseURL: process.env.REACT_APP_ENABLE_LOCALHOST_API==='1' ? window.location.origin : 'https://www.designsense.ai',
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
    },
}

// export const stripeKey = "pk_live_51NsIYxLY1gvphGTt9mZX0Kgm29JTLQBCBLo7ZLtzXu0qscHrVQMGI5kOXTnk1KR2aXq3iGwjR4qAnzu6XbnIFH0h008pk9zqxb";
// export const stripeKey = "pk_test_pjaHBMeEKNEHwWTtWWc8GrXf00Xwm5gLxc";


// export const stripeKey = "pk_test_51NsIYxLY1gvphGTtLfplc6CZ5fk14HaCX95JdFQs7VAWPHumzGcgZcXqGTtCZs0g30WmyIvF23sQDxtKTzYjlyK400VrDCSmMt";

// export const stripeKey = "pk_live_51NsIYxLY1gvphGTt9mZX0Kgm29JTLQBCBLo7ZLtzXu0qscHrVQMGI5kOXTnk1KR2aXq3iGwjR4qAnzu6XbnIFH0h008pk9zqxb";
// export const stripeKey = "pk_test_pjaHBMeEKNEHwWTtWWc8GrXf00Xwm5gLxc";


export const stripeKey = "pk_test_51KokFSI4I0Vw9jGhhaP7TlwIi8heEAs5c7eBnEO3NCropy9f4NkXLCX1E9iXP5OQlhMd93A4lJ013UzaL2G4yOx200TJgF0ksa";

